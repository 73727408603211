<template>
  <div class="relative h-full min-h-screen">
    <div class="p-10">
      <router-view />
    </div>

    <div class="pt-10"></div>
  </div>
</template>

<script>

</script>
